<template>
    <div class="container">
        <div class="title">
            {{title}}
            <slot name="upload-btns"></slot>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {};
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {}
};
</script>
<style lang="scss" scoped>
.container {
    padding: 30px 0 0 0;
    .title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        position: relative;
        margin-left: 34px;
        margin-bottom: 20px;
        @include flex(row, flex-start, flex-start);
        &::before {
            content: '';
            width: 4px;
            height: 20px;
            background: #dc6f7c;
            position: absolute;
            left: -34px;
            top: -1px;
            bottom: 0;
        }
    }
    .content {
        width: 100%;
        box-sizing: border-box;
        padding: 0 34px;
    }
}
</style>