<template>
    <div class="container">
        <div class="edit">
            <div class="header">
                <div class="left">
                    <span>查看月评模版</span>
                </div>
                <div class="right" v-if="viewMode === 'on'">
                    <el-button round @click="handleEdit" v-if="canEdit">
                        <i class="iconfont icon-edit" style="position: relative;top: 1px;"></i> 编辑</el-button>
                </div>
            </div>
            <div class="edit-container">
                <div class="edit-header">
                    <div class="floor">月评模板</div>
                </div>
                <div class="edit-content">
                    <resources-item title="单元主题">
                        <div class="edit-form">
                            <el-col class="edit-form-item" :lg="11">
                                <div class="left">主题名称</div>
                                <div class="right">
                                    <span class="form-item">{{editForm.name}}</span>
                                </div>
                            </el-col>
                            <el-col class="edit-form-item" :lg="13">
                                <div class="left">主题时间</div>
                                <div class="right">
                                    <div class="date-picker-group form-item">
                                        <span class="date-picker">{{editForm.beginTime}}</span>
                                        <div class="split-line">-</div>
                                        <span class="date-picker">{{editForm.endTime}}</span>
                                    </div>
                                </div>
                            </el-col>
                            <el-col class="edit-form-item target" :span="24">
                                <div class="left">主题目标</div>
                                <div class="right">
                                    <span class="form-item">{{editForm.target}}</span>
                                </div>
                            </el-col>
                        </div>
                    </resources-item>
                    <resources-item title="发展目标">
                        <div class="edit-faq"
                            v-for="(configItem,configIndex) in editForm.config"
                            :key="configIndex" v-show="configItem.type === 'ul-faq'">
                            <div v-for="(faqItem, faqIndex) of configItem.content"
                                :key="faqIndex" class="edit-faq-item">
                                <div class="edit-faq-item-title">
                                    <el-col class="left" :lg="15" :sm='11'>
                                        <div class="index"><span>{{(faqIndex+1+'').padStart(2, '0')}}</span></div>
                                        <div class="name"><span>{{faqItem.title}}</span></div>
                                    </el-col>
                                    <el-col class="right" :lg="9" :sm='13'></el-col>
                                </div>
                                <el-collapse class="edit-faq-item-list">
                                    <el-collapse-item  class="list-item"
                                        v-for="(listItem, listIndex) in faqItem.list" :key="listIndex">
                                        <template slot="title">
                                            <div class="list-item-title">
                                                <el-col class="left" :lg="22" :sm='23'>
                                                    <div class="index"><span>{{listIndex+1}}</span></div>
                                                    <div class="name">
                                                        <span>{{listItem.name}}</span>
                                                    </div>
                                                </el-col>
                                                <el-col class="right" :lg="2" :sm='1'>
                                                </el-col>
                                            </div>
                                        </template>
                                        <div class="edit-faq-item-icon"
                                            v-for="(optionsItem, optionsIndex) in listItem.options" :key="optionsIndex">
                                            <el-col class="left" :span="3">
                                                <icon-select v-model="optionsItem.icon" placeholder="icon匹配"
                                                    popper-class='icon-select-dropdown' disabled>
                                                    <el-option v-for="(item,index) in iconList" :key="index"
                                                        :label="item.src" :value="item.index">
                                                        <img :src="item.src" alt="" :title='item.desc' />
                                                    </el-option>
                                                    <template slot="prefix"></template>
                                                </icon-select>
                                            </el-col>
                                            <el-col class="right" :span="21">
                                                <!-- <span>{{optionsItem.value}}</span> -->
                                                <el-input placeholder="请输入内容" v-model="optionsItem.value" readonly></el-input>
                                            </el-col>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                    </resources-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
import _ from '@kaobeimao/core';
import ResourcesItem from '@/components/resources/ResourcesItem';

export default {
    components: {
        ResourcesItem,
        IconSelect: () => import('./components/IconSelect')
    },
    data() {
        return {
            loading: false,
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
            iconList: Core.Const.EVALUATION_ICONLIST,
            addListName: [''],
            editForm: {
                id: '',
                plan_id: '',
                name: '',
                month: '',
                target: '',
                endTime: '',
                beginTime: '',
                config: JSON.parse(JSON.stringify(Core.Const.EVALUATION_CONFIG.DEFAULT)),
            },
            viewMode: 'off',
            isSaved: false,
            canEdit: 0,
        };
    },
    created() {
        this.editForm.id = this.$route.query.id;
        this.editForm.plan_id = this.$route.query.plan_id;
        this.canEdit =  this.$route.query.can_edit;
        this.viewMode = this.$route.query.view_mode
        if(this.editForm.id){
            this.getEvaluation()
        }
    },
    methods: {
        getEvaluation(){
            console.log("getEvaluation -> this.editForm.id", this.editForm.id);
            Core.Api.Evaluation.detail(
                this.editForm.id
            ).then(res => {
                console.log("getEvaluation -> res", res)
                let item = res.detail
                this.editForm.name = item.topic.name;
                this.editForm.month = item.month;
                this.editForm.target = item.topic.target;
                this.editForm.config = JSON.parse(item.config);
                this.editForm.beginTime = this.timeFormat(item.begin_time);
                this.editForm.endTime = this.timeFormat(item.end_time);
                console.log("getEvaluation -> this.editForm", this.editForm)
            })
        },
        handleEdit(){ //点击编辑
            this.$router.replace({
                path: '/plan/evaluation-edit',
                query: { id: this.editForm.id , plan_id: this.editForm.plan_id}
            });
        },
        timeFormat(time){
            time = new Date(time * 1000)

            let year = time.getFullYear(),
                month = time.getMonth() + 1,
                date = time.getDate();
            return `${year}年${month}月${date}日`
        },
        routerChange(key, q = '') { // 路由跳转
            switch (key) {
                case 1:
                    this.$router.go(-1);
                break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    .edit {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        .header {
            @include flex(row, space-between, center);
            margin-bottom: 14px;
            .left {
                font-size: 20px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                @include ftb;
                .el-icon-back {
                    font-size: 24px;
                    color: #1D517E;
                    font-weight: 800;
                    margin-right: 10px;
                    cursor: pointer;
                }
                span {
                    margin-right: 14px;
                }
            }
        }
        .edit-container {
            border-radius: 6px;
            margin-bottom: 30px;
            .edit-header {
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(251, 252, 253, 1) 0%,
                    rgba(246, 248, 250, 1) 100%
                );
                border-radius: 6px 6px 0 0;
                .floor {
                    width: 100%;
                    height: 60px;
                    box-sizing: border-box;
                    padding: 0 34px;
                    border-radius: 6px 6px 0px 0px;
                    @include flex(row, space-between, center);
                }
            }
            .edit-content {
                width: 100%;
                box-sizing: border-box;
                padding-bottom: 30px;
                background-color: #fff;
                .edit-form {
                    width: 100%;
                    @include flex(row, flex-start, center);
                    flex-wrap: wrap;
                    .edit-form-item {
                        // width: 100%;
                        margin-bottom: 20px;
                        @include ftb;
                        &.target{
                            align-items: flex-start;
                            .form-item{
                                white-space: pre-wrap;
                            }
                        }
                        .left {
                            width: 80px;
                            font-weight: 600;
                        }
                        .right {
                            width: calc(100% - 80px);
                            padding-right: 3vw;
                            .form-item{
                                width: 100%;
                                box-sizing: border-box;
                                .date-picker{
                                    text-align: left;
                                }
                                .split-line{
                                    padding: 0 7px 0 5px;
                                }
                            }
                            span.form-item,span.date-picker{
                                font-size: 14px;
                            }
                        }
                    }
                }
                .edit-faq {
                    width: 100%;
                    .edit-faq-item {
                        width: 100%;
                        margin-bottom: 30px;
                        background:#f9fafc;
                        border-radius:4px;
                        border:1px solid #eaeef1;
                        .index{
                            width: 44px;
                            height: 44px;
                            font-size:16px;
                            font-weight:600;
                            color:#1D517E;
                            line-height:44px;
                            text-align: center;
                            i{
                                font-weight:600;
                            }
                        }
                        .name{
                            width: calc(100% - 44px);
                            overflow: hidden;
                            span{
                                color:rgba(50,53,56,1);
                                font-size: 14px;
                            }
                        }
                        &-title{
                            width: 100%;
                            height:44px;
                            @include flex(row, space-between, center);
                            .left{
                                @include flex(row, flex-start, center);
                                .name{
                                    span{
                                        font-weight:600;
                                    }
                                }
                            }
                        }
                        &-list{
                            .list-item{
                                .el-collapse-item__header{
                                    min-height: 44px;
                                    line-height: 44px;
                                    border-bottom: 1px solid #eaeef1;
                                    padding-right: 20px;
                                    &:hover{
                                        background-color:rgba(234, 238, 241, 1);
                                        .list-item-title{
                                            background-color:rgba(234, 238, 241, 1);
                                        }
                                    }
                                    i{
                                        font-size: 20px;
                                        color: rgba(24, 39, 64, 0.3);
                                    }
                                }
                                .el-collapse-item__content{
                                    background-color: #F9FBFC;
                                    box-sizing: border-box;
                                    padding: 10px 20px 44px 44px;
                                    overflow: hidden;
                                }
                                &-title{
                                    width: 100%;
                                    @include flex(row, space-between, center);
                                    background-color: #fff;
                                    .left{
                                        @include flex(row, space-between, center);
                                        .index{
                                            border-right: 1px solid rgba(234,238,241,1);
                                        }
                                        .name{
                                            padding-left: 20px;
                                            box-sizing: border-box;
                                            @include flex(row, flex-start, center);
                                        }
                                    }
                                    .right{
                                        @include flex(row, flex-end, center);
                                        padding-right: 10px;
                                    }
                                    .add-list{
                                        &:hover{
                                            background-color:#fcf1f2;
                                        }
                                    }
                                }
                                .edit-faq-item-icon{
                                    margin-bottom: 4px;
                                    overflow: hidden;
                                    background-color: #fff;
                                    input{
                                        border-radius: 0;
                                    }
                                    .left{
                                        border: 1px solid #DCDFE6;
                                        .icon-select{
                                            width: 100%;
                                            box-sizing: border-box;
                                            input:read-only{
                                                visibility: hidden;
                                                width: 100%;
                                                height: 34px;
                                            }
                                            .el-input__prefix{
                                                width: calc(100% - 30px);
                                                left: 0;
                                                top: 0;
                                                text-align: center;
                                                @include flex(row, center, center);
                                            }
                                        }
                                    }
                                    .right{
                                        @include flex(row, space-between, center);
                                        border: 1px solid #DCDFE6;
                                        box-sizing: border-box;
                                        border-left-color: transparent;
                                        .el-input{
                                            input{
                                                border: 0;
                                                height: 34px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.container .edit .edit-container .edit-content .edit-faq {
    .edit-faq-item {
        &-list{
            .list-item{
                &:last-of-type {
                    .el-collapse-item__header {
                        border-bottom: none;
                    }
                }
                .el-collapse-item__header{
                    min-height: 44px;
                    line-height: 44px;
                    border-bottom: 1px solid #eaeef1;
                    padding-right: 20px;
                    &:hover{
                        background-color:rgba(234, 238, 241, 1);
                        .list-item-title{
                            background-color:rgba(234, 238, 241, 1);
                        }
                    }
                    i{
                        font-size: 20px;
                        color: rgba(24, 39, 64, 0.3);
                    }
                }
                .el-collapse-item__content{
                    background-color: #F9FBFC;
                    box-sizing: border-box;
                    padding: 10px 20px 44px 44px;
                    overflow: hidden;
                }
                .edit-faq-item-icon{
                    input{
                        border-radius: 0;
                    }
                    .left{
                        .icon-select{
                            input:read-only{
                                visibility: hidden;
                                width: 100%;
                                height: 34px;
                            }
                            .el-input__prefix{
                                width: calc(100% - 30px);
                                left: 0;
                                top: 0;
                                text-align: center;
                                @include flex(row, center, center);
                            }
                        }
                    }
                    .right{
                        .el-input{
                            input{
                                border: 0;
                                height: 34px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>